(function($) {
    $(document).ready(function() {
        // Navigation
        $('#navbar #menu-main-menu > .menu-item > a').mouseenter(function(e) {
            e.preventDefault();

            $this = $(this);
  
            // Close existing open submenus
            $('#navbar #menu-main-menu > .menu-item > a').removeClass('active');
  
            if ($this.closest('.menu-item').hasClass('menu-item-has-children')) {    
                $this.addClass('active');
                $('body').addClass('has-submenu-expanded');

                var submenu = $this.data('target');
                $('#submenu').find('.submenu-item').hide();
                $('#submenu').show().find('.' + submenu).css('left', $this.offset().left).show();
            }
        });

        $(document).click(function(e) {
            if ($('body').hasClass('has-submenu-expanded') && $(e.target).closest('#submenu').length == 0) {                
                $('#navbar #menu-main-menu > .menu-item > a').removeClass('active');
                $('#submenu').hide();

                $('body').removeClass('has-submenu-expanded');
            }
        });

        $('ul#mobile-menu .menu-item > a').click(function(e) {
            $this = $(this);

            if ($this.closest('.menu-item').hasClass('menu-item-has-children')) {
                e.preventDefault();

                var submenu = $this.data('target');

                $('#submenu').find('.submenu-item').hide();
                $('#submenu').show().find('.' + submenu).show();

                $('div#mobile-menu').addClass('slide-left');
                $('#submenu').addClass('show');

                $('.blocker').height($(document).height()).show();;
            }
        });

        $('.spinner-spin').click(function(e) {
            if ($('#spinner-form').is(':checked') == true) {
                $('#submenu').removeClass('show');
                $('div#mobile-menu').removeClass('slide-left');
                $('.blocker').hide();
            }
            else {
                // alert($('#spinner-form').is(':checked'));
                // $('body').addClass('has-submenu-expanded');
                $('.blocker').height($(document).height()).show();
            }
        });

        $('#submenu .submenu-item .back').click(function(e) {
            e.preventDefault();

            $('#submenu').removeClass('show');
            $('div#mobile-menu').removeClass('slide-left');
        });

        function responsiveAdjustments() {
            if ($(window).width() > 991) {
                $('#submenu').removeClass('show').hide();
                $('div#mobile-menu').removeClass('slide-left');
                $('.blocker').hide();
            }
        }

        $(window).on('resize',responsiveAdjustments);
    });
  })(jQuery);